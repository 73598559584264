import Vue from 'vue'
import Router from 'vue-router'
import InstallZoom from '../root_components/InstallZoom'
import Login from '../root_components/Login'
import ZoomMeetingURL from '../root_components/ZoomMeetingURL'
import AuthGuard from './AuthGuard'
import LoginGuard from './LoginGuard'
import NotCustomDomainGuard from './NotCustomDomainGuard'
import Home from '../root_components/Home'
import ProgramView from '../root_components/ProgramView'
import UserAccounts from '../root_components/UserAccounts'
import ActivateUserAccount from '../root_components/ActivateUserAccount'
import Invitation from '../root_components/Invitation'
import ForgotPassword from '../root_components/ForgotPassword'
import ResetPassword from '../root_components/ResetPassword'
import SignUp from '../root_components/SignUp'
import CreateVS from '../root_components/CreateVS'
import UserProfile from '@/common_router_pages/UserProfile'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/zoom/install',
      name: 'InstallZoom',
      component: InstallZoom,
      beforeEnter: AuthGuard
    },
    {
      path: '/zoom/meeting',
      name: 'ZoomMeetingURL',
      component: ZoomMeetingURL
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: LoginGuard
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp,
      beforeEnter: LoginGuard
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: AuthGuard
    },
    {
      path: '/user/profile',
      name: 'UserProfile',
      component: UserProfile,
      beforeEnter: NotCustomDomainGuard
    },
    {
      path: '/user/accounts',
      name: 'UserAccounts',
      component: UserAccounts,
      beforeEnter: NotCustomDomainGuard
    },
    {
      path: '/vs/create',
      name: 'CreateVS',
      component: CreateVS,
      beforeEnter: NotCustomDomainGuard
    },
    {
      path: '/invitation',
      name: 'Invitation',
      component: Invitation,
      beforeEnter: LoginGuard
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      beforeEnter: LoginGuard
    },
    {
      path: '/account/reset-password/:uid/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      props: true,
      beforeEnter: LoginGuard
    },
    {
      path: '/account/activate/:uid/:token',
      name: 'ActivateUserAccount',
      props: true,
      component: ActivateUserAccount
    },
    {
      path: '/programs/:pid',
      name: 'ProgramView',
      props: true,
      component: ProgramView
    },
    {
      path: '*',
      name: 'PageNotFound',
      beforeEnter: (to, from, next) => {
        next('/')
      }
    }
  ],
  mode: 'history'
})
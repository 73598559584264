<template>
  <v-container class="fill-height app-gradient" fluid>
    <loading-dialog v-model="isSVLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-menu close-on-content-click>
          <template v-slot:activator="{ on }">
           <v-btn v-on="on" class="me-2" text dark>
              <v-icon left>mdi-web</v-icon>
              {{ current_lang === 'en' ? 'English' : 'العربية' }}
              <v-icon right>mdi-chevron-down</v-icon>
           </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group v-model="current_lang">
              <v-list-item value="en">
                <v-list-item-content>
                  <v-list-item-title class="font-14">
                    English
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="ar" class="mt-1">
                <v-list-item-content>
                  <v-list-item-title class="font-14">
                    العربية
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-card class="mt-2 elevation-12 pt-4 pb-4 ps-4 pe-4 rounded-xl" color="login_background">
          <v-card-text>
            <v-form @submit.prevent="createAccount">
              <v-row justify="center" class="pb-0">
                <v-col cols="12" class="text-center">
                  <a :href="constants.SV_CMS_URL" tag="span" style="cursor: pointer">
                    <img :src="$vuetify.theme.dark ? require('@/assets/logo_white.png') : require('@/assets/logo.png')" 
                    align="middle" alt="SmartVoom" height="50" />
                  </a>
                  <div class="secondary--text font-20 mt-2">{{ form_title }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="create_account_obj.first_name"
                    :label="$vuetify.lang.t('$vuetify.login_signup.first_name')"
                    type="text"
                    rounded
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    class="mt-2"
                    v-model="create_account_obj.last_name"
                    :label="$vuetify.lang.t('$vuetify.login_signup.last_name')"
                    type="text"
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    class="mt-2"
                    v-model="create_account_obj.password"
                    :label="$vuetify.lang.t('$vuetify.login_signup.password')"
                    type="password"
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    class="mt-2"
                    v-model="create_account_obj.confirm_password"
                    :label="$vuetify.lang.t('$vuetify.login_signup.confirm_password')"
                    type="password"
                    rounded
                    :rules="[passwords_match]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn 
                  class="textTransformNone"
                  color="primary" 
                  type="submit"
                  :disabled="isSVLoading || !form_valid"
                  block
                  rounded>
                    {{ $vuetify.lang.t('$vuetify.general.confirm') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0 mt-2 text-center">
                  <div class="font-16 weight-600">
                    {{ $vuetify.lang.t('$vuetify.login_signup.have_an_account_question') }}
                    <a
                      style="text-decoration: underline;"
                      class="font-15"
                      @click.prevent="$router.push('/login')">
                      {{ $vuetify.lang.t('$vuetify.pages.login') }}
                    </a>
                  </div>
                  <div class="mt-2">
                    By continuing, you confirm that you agree to our
                    <a style="text-decoration: underline;" @click="openInNewTab(constants.SV_CMS_URL + '/legal/terms')">
                      Terms of Service
                    </a>
                    and
                    <a style="text-decoration: underline;" @click="openInNewTab(constants.SV_CMS_URL + 'legal/privacy-policy')">
                      Privacy Policy
                    </a>
                    . We will email you if anything changes and you can opt out any time.
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <div class="mt-5 text-center white--text">
          All rights reserved.
          <span
           class="white--text weight-600" >
            SmartVoom, Inc.
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    data () {
      return {
        constants: null,
        create_account_obj: {
          first_name: '',
          last_name: '',
          password: '',
          confirm_password: ''
        },
        isSVLoading: false,
        isError: false,
        errorMessage: ''
      }
    },
    computed: {
      form_title () {
        return this.$vuetify.lang.t('$vuetify.login_signup.sign_up')
      },
      form_valid () {
        return this.create_account_obj.first_name.length > 0 && 
            this.create_account_obj.last_name.length > 0 && 
            this.create_account_obj.password.length > 0 && 
            this.create_account_obj.email.length > 0 &&
            (this.create_account_obj.password === this.create_account_obj.confirm_password)
      },
      passwords_match () {
        if (this.create_account_obj.password !== this.create_account_obj.confirm_password) {
          return this.$vuetify.lang.t('$vuetify.login_signup.pass_no_match')
        }
        return true
      },
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      },
      iid () {
        if (this.$route.query.iid === undefined) {
          return null
        }
        return this.$route.query.iid
      },
      token () {
        if (this.$route.query.token === undefined) {
          return null
        }
        return this.$route.query.token
      }
    },
    methods: {
      createAccount () {
        if (!this.form_valid) {
          return
        }
        this.isSVLoading = true
        var data = Object.assign({}, this.create_account_obj)
        data.iid = this.iid
        data.token = this.token
        this.$http.post(this.main_base_url + '/auth/users/confirm_invitation/', data, this.main_api_key_header).then(response => {
          this.isSVLoading = false
          this.$cookies.set(constants.cookies.TOKEN, response.body.access_token, null, null, constants.COOKIES_DOMAIN_NAME)
          this.$cookies.set(constants.cookies.SESSION_ID, response.body.session_id, null, null, constants.COOKIES_DOMAIN_NAME)
          if (response.body.user.user_type === 1) {
            this.$cookies.set(constants.cookies.ED_ACCOUNT_TYPE, response.body.user.account_type, null, null, constants.COOKIES_DOMAIN_NAME)
            window.location.href = '/'
          } else {
            window.location.href = '/user/accounts'
          }
        }, error => {
          this.isSVLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
        })
      },
      getInvitationData () {
        var query = '?iid=' + encodeURI(this.iid) + '&token=' + encodeURI(this.token)
        this.isSVLoading = true
        this.$http.get(this.main_base_url + '/auth/users/invitation_data/' + query, this.main_api_key_header).then(response => {
          this.isSVLoading = false
          this.create_account_obj.first_name = response.body.first_name !== null ? response.body.first_name : ''
          this.create_account_obj.last_name = response.body.last_name !== null ? response.body.last_name : ''
          this.create_account_obj.email = response.body.email
        }, error => {
          this.isSVLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
        })
      },
      setTitle () {
        document.title = this.form_title + ' | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.constants = constants
      this.setTitle()
      this.getInvitationData()
    }
  }
</script>

<style>
  .or-span:after,
  .or-span:before{
      content:"\00a0\00a0\00a0\00a0\00a0";
      text-decoration:line-through;
  }
</style>
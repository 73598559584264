import Vue from 'vue'
import constants from '@/plugins/constants'

Vue.mixin({
  data: function() {
    return {
      get basic_url () {
        return process.env.VUE_APP_PROTOCOL + '://' + process.env.VUE_APP_SERVER_DNS + '/api'
      },
      get main_base_url () {
        return process.env.VUE_APP_PROTOCOL + '://' + process.env.VUE_APP_SERVER_DNS + '/api'
      },
      get bad_request () {
        return 400
      },
      get unauthorized () {
        return 401
      },
      get forbidden () {
        return 403
      },
      get api_key_header () {
        return {headers: {'Authorization': 'Api-Key ' + process.env.VUE_APP_API_KEY}}
      },
      get main_api_key_header () {
        return {headers: {'Authorization': 'Api-Key ' + process.env.VUE_APP_API_KEY}}
      },
      get is_custom_domain () {
        return process.env.VUE_APP_CUSTOM_DOMAIN === 'true'
      },
      get ed_team_id () {
        return process.env.VUE_APP_ED_TEAM_ID
      }
    }
  },
  computed: {
    maxPageWidth () {
      if (this.$vuetify.breakpoint.xlOnly) {
        return '1430px'
      } else if (this.$vuetify.breakpoint.lgOnly) {
        return '1200px'
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return '1000px'
      }
      return '900px'
    },
    maxPageDivWidth () {
      if (this.$vuetify.breakpoint.xlOnly) {
        return '1400px'
      } else if (this.$vuetify.breakpoint.lgOnly) {
        return '1170px'
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return '970px'
      }
      return '900px'
    },
  },
  methods: {
    loadCookies () {
      this.$store.dispatch('setLang', this.$cookies.get(constants.cookies.LANG) ? 
        this.$cookies.get(constants.cookies.LANG) : 'en')
      this.$store.dispatch('setLoginData', {
        token: this.$cookies.get(constants.cookies.TOKEN),
        session_id: this.$cookies.get(constants.cookies.SESSION_ID),
        account_id: this.$cookies.get(constants.cookies.SELECTED_ACCOUNT_ID),
        account_type: this.$cookies.get(constants.cookies.ED_ACCOUNT_TYPE),
      })
    },
    authGuard (query) {
      if (this.$store.getters.token === null) {
        this.$router.push({ name: 'Login', query: query })
      }
    },
    openInNewTab (url) {
      var win = window.open(url, '_blank')
      win.focus()
    },
    formatTime (time, format) {
      var moment = require('moment')
      moment.locale(this.$store.getters.lang)
      var date = moment.utc(time, 'HH:mm:ss').local()
      return date.format(format)
    },
    formatDate (date_str, format) {
      var moment = require('moment')
      moment.locale(this.$store.getters.lang)
      var date = moment(date_str)
      return date.format(format)
    },
    formatDateTime (date_str, format = 'LLLL') {
      var moment = require('moment')
      moment.locale(this.$store.getters.lang)
      var date = moment(date_str)
      return date.format(format)
    },
    localTimeToUtc (time) {
      var moment = require('moment')
      moment.locale('en')
      var date = new moment(time, 'HH:mm:ss').utc()
      return date.format('HH:mm:ss')
    },
    utcToLocalTime (time) {
      var moment = require('moment')
      moment.locale('en')
      var date = moment.utc(time, 'HH:mm:ss').local()
      return date.format('HH:mm:ss')
    },
    localDateTimeToUtc (time) {
      var moment = require('moment')
      moment.locale('en')
      var date = new moment(time, 'YYYY-MM-DDTHH:mm').utc()
      return date.format('YYYY-MM-DDTHH:mm')
    },
    utcDateTimeToLocal (time) {
      var moment = require('moment')
      moment.locale('en')
      var date = moment.utc(time, 'YYYY-MM-DDTHH:mm').local()
      return date.format('YYYY-MM-DDTHH:mm')
    },
    isLessThanNow (date) {
      var moment = require('moment')

      var current_time = moment()
      var shown_at = moment(this.utcDateTimeToLocal(date))
      var dif = current_time.diff(shown_at)
      return dif > 0
    },
    updateUserPreferences (data) {
      if (this.$store.getters.token === null) {
        return
      }
      var auth_header = {
        headers: {
          Authorization: 'Token ' + this.$store.getters.token,
        }
      }
      this.isLoading = true
      this.$http.post(this.basic_url + '/auth/users/preferences/', data, auth_header).then(response => {
        this.isLoading = false
        this.$store.dispatch('setReloadUserInfo', true)
      }, error => {
        this.isLoading = false
        if (error.status === this.unauthorized) {
          this.$store.dispatch('setUnauthorizedDialog', true)
        }
      })
    },
  }
})
<template>
  <v-app>
    <div>
      <loading-dialog v-model="isLoading"></loading-dialog>
      <app-bar v-if="$route.name === 'ProgramView' || $route.name === 'UserProfile'"></app-bar>
    </div>
    <v-main class="background_color py-0">
      <router-view></router-view>
    </v-main>
    <v-style>
      .v-application {
         font-family: 'Poppins', 'Tajawal', sans-serif !important;
          .title { // To pin point specific classes of some components
             font-family: 'Poppins', 'Tajawal', sans-serif !important;
          }
       }
    </v-style>
  </v-app>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false
      }
    },
    watch: {
      '$store.state.reloadUserInfo': function (value) {
        if (value) {
          this.getUserInfo()
        }
      }
    },
    computed: {
      isLoggedIn () {
        return this.$store.getters.token !== null
      }
    },
    methods: {
      getUserInfo () {
        this.isLoading = true
        var headers = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
          }
        }
        this.$http.get(this.basic_url + '/generic/user_info/', headers).then(response => {
          this.isLoading = false
          this.$store.dispatch('setUser', response.body.user)
          this.$store.dispatch('setDataLoading', false)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      this.loadCookies()
      if (this.isLoggedIn) {
        this.getUserInfo()
      } else {
        this.$store.dispatch('setDataLoading', false)
      }
      if (this.$store.getters.lang === 'en') {
        this.$vuetify.lang.current = 'en'
        this.$vuetify.rtl = false
      } else {
        this.$vuetify.lang.current = 'ar'
        this.$vuetify.rtl = true
      }
    },
    // ready () {
    //   setInterval(function () {
    //     location.reload()
    //   }.bind(this), 3)
    // }
  }
</script>

<style>

  .underlined {
    text-decoration: underline;
  }

  .textTransformNone {
    text-transform: none !important;
  }

  .textTransformCap {
    text-transform: capitalize !important;
  }

  .v-card__text, .v-card__title {
    word-break: normal !important;   
  }

  .weight-300 {
    font-weight: 300 !important;
  }

  .weight-500 {
    font-weight: 500 !important;
  }

  .weight-600 {
    font-weight: 600 !important;
  }

  .bold {
    font-weight: 700 !important;
  }

  .weight-800 {
    font-weight: 800 !important;
  }

  .weight-900 {
    font-weight: 900 !important;
  }

  .italic {
    font-style: italic !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .font-13 {
    font-size: 13px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-15 {
    font-size: 15px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }
  .font-17 {
    font-size: 17px !important;
  }
  .font-18 {
    font-size: 18px !important;
  }
  .font-19 {
    font-size: 19px !important;
  }
  .font-20 {
    font-size: 20px !important;
  }

  .font-21 {
    font-size: 23px !important;
  }

  .font-22 {
    font-size: 23px !important;
  }

  .font-23 {
    font-size: 23px !important;
  }

  .font-24 {
    font-size: 25px !important;
  }

  .font-25 {
    font-size: 24px !important;
  }

  .font-30 {
    font-size: 30px !important;
  }

  .font-35 {
    font-size: 35px !important;
  }

  .font-40 {
    font-size: 40px !important;
  }

  .userSettings{
    font-size: 13px;
  }
  .userSettingsOrg{
    font-size: 12px;
    font-weight: bold;
  }
  .userSettingsBold{
    font-size: 13px;
    font-weight: bold;
  }
  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /*.app-gradient {
    background: rgb(96,86,158);
    background: linear-gradient(104deg, rgba(96,86,158,1) 0%, rgba(21,6,116,1) 52%, rgba(68,68,68,1) 100%);
  }*/

  .app-gradient {
    background: rgb(0,77,64);
    background: linear-gradient(333deg, rgba(0,77,64,1) 0%, rgba(70,114,135,1) 52%, rgba(30,98,54,1) 100%);
  }
  .app-gradient-dark {
    background: rgb(0,77,64);
    background: linear-gradient(333deg, rgba(0,77,64,1) 0%, rgba(70,114,135,1) 52%, rgba(30,98,54,1) 100%);
  }

</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import en from '../localization/en'
import ar from '../localization/ar'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, ar },
    current: 'en'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.teal.darken3,
        secondary: colors.grey.darken3,
        accent: colors.teal.darken3,
        primary_text: colors.grey.darken4,
        secondary_text: colors.grey.darken3,
        background_color: colors.grey.lighten4,
        card_color: colors.green.lighten5,
        selection_card_color: colors.green.lighten5,
        third_color: colors.teal.lighten4
      },
      dark: {
        primary: colors.teal,
        secondary: colors.teal.darken4,
        accent: colors.teal,
        primary_text: colors.grey.lighten5,
        secondary_text: colors.grey.lighten2,
        background_color: colors.grey.darken5,
        selection_card_color: colors.grey.darken4,
        card_color: colors.blueGrey.darken4,
        third_color: colors.blueGrey.darken3
      }
    }
  }
})

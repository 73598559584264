<template>
  <v-container class="fill-height" fluid>
    <loading-dialog v-model="isSVLoading"></loading-dialog>
    <v-row align="center" justify="center" class="fill-height" v-if="isActivated">
      <v-col
        cols="12"
        sm="10"
        md="8"
        lg="6"
      >
        <v-card class="mt-2 elevation-12 pt-4 pb-4 ps-4 pe-4" color="card_color">
          <router-link to="/" tag="span" style="cursor: pointer">
            <img :src="$vuetify.theme.dark ? require('@/assets/logo_white.png') : require('@/assets/logo.png')" 
            align="middle" alt="SmartVoom" height="50" />
          </router-link>
          <v-card-title class="font-25 bold secondary--text">
            {{ $vuetify.lang.t('$vuetify.account.activated_ttl') }}
          </v-card-title>
          <v-card-text class="font-17">
            {{ $vuetify.lang.t('$vuetify.account.activated_msg') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined to="/">
              {{ $vuetify.lang.t('$vuetify.general.back_home') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="mt-5 text-center">
          All rights reserved.
          <span
           class="bold" >
            SmartVoom, Inc.
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isActivated: false,
        isSVLoading: false
      }
    },
    props: ['uid', 'token'],
    computed: {
      form_title () {
        return this.$vuetify.lang.t('$vuetify.login_signup.account_activation')
      }
    },
    methods: {
      activateAccount () {
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
          }
        }
        this.isSVLoading = true
        this.$http.post(this.main_base_url + '/auth/users/activate_email/', {uid: this.uid, token: this.token}, auth_header).then(response => {
          this.isSVLoading = false
          this.isActivated = true
          this.$store.dispatch('setReloadUserInfo', true)
        }, error => {
          this.isSVLoading = false
          window.location.href = '/'
        })
      },
      setTitle () {
        document.title = this.form_title + ' | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.setTitle()
      this.authGuard({
       redirection_type: 'activate_account',
       activation_uid: this.uid,
       activation_token: this.token,
      })
      this.activateAccount()
    }
  }
</script>
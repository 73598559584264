import ar from 'vuetify/es5/locale/en'

export default {
  ...ar,
  accounts: {
    choose_account: 'اختر حساب',
    creator: 'معلم / مدير',
    student: 'طالب',
    create_vs: 'انشاء مدرسة افتراضية جديدة',
    school_name: 'اسم المدرسة',
    invitation: '(دعوة)',
    accept: 'قبول',
    decline: 'رفض'
  },
  pages: {
    login: 'تسجيل الدخول',
    sign_up: 'انشاء حساب'
  },
  general: {
    confirm: 'تأكيد',
    general_error: 'حدث خطأ، يرجى المحاولة لاحقا',
    app_name: 'سمارت ڤووم',
    loading: 'الرجاء الإنتظار...',
    back_home: 'العودة إلى الرئيسية',
  },
  login_signup: {
    sign_up: 'انشاء حساب',
    sign_in: 'تسجيل الدخول',
    email: 'البريد الإلكتروني',
    username_or_email: 'البريد الإلكتروني',
    password: 'كلمة السر',
    first_name: 'الاسم الاول',
    last_name: 'اسم العائلة',
    confirm_password: 'تاكيد كلمة السر',
    continue: 'متابعة',
    pass_no_match: 'كلمتي السر غير متطابقتان',
    no_account: 'هذا الحساب غير موجود',
    unable_to_login: 'بيانات دخول خاطئة',
    category: 'القسم',
    subcategory: 'القسم الفرعي',
    username: 'اسم المستخدم',
    no_account_question: 'ليس لديك حساب؟',
    have_an_account_question: 'لديك حساب من قبل؟',
    team_name: 'الإسم',
    create_first_team: 'انشئ فريقك التعليمي الأول',
    create_team: 'انشاء فريق تعليمي جديد',
    account_activation: 'تفعيل الحياب',
    continue_facebook: 'المتابعة بإستخدام الفيسبوك',
    forgot_password: 'هل نسيت كلمة السر؟',
    or: 'أو',
    fb_error: 'نعتذر، حدث خطأ في عملية التواصل مع الفيسبوك'
  },
  program_view: {
    preview_text: 'هذه معاينة ، بصفتك مشرفًا / مدرسًا لن تتمكن من التسجيل.',
    enrollment_open: 'التسجيل مفتوح',
    enrollment_closed: 'التسجيل مغلق',
    description: 'الوصف',
    curriculum: 'المنهاج',
    session: 'الجلسة',
    free: 'مجاني',
    enrollment_closes_in: 'ينتهي التسجيل بعد',
    watch_video: 'مشاهدة الفيديو',
    show_more: 'عرض المزيد',
    show_less: 'اخفاء',
    enroll_now: 'اشترك الآن',
    pay_now: 'ادفع الآن',
    days: 'يوم',
    hours: 'ساعة',
    minutes: 'دقيقة',
    seconds: 'ثانية',
    success_enrolled: 'لقد قمت بالإشتراك بنجاح في'
  },
  account: {
    view_profile: 'عرض صفحتي الشخصية',
    wallet: 'المحفظة',
    logout: 'تسجيل الخروج',
    profile: 'الملف الشخصي',
    your_name: 'اسمك',
    change_password: 'تعديل كلمة السر',
    current_password: 'كلمة السر الحالية',
    new_password: 'كلمة السر الجديدة',
    confirm_new_password: 'تاكيد كلمة السر الجديدة',
    profile_success: 'تم تعديل ملفك الشخصي بنجاح',
    password_success: 'تم تعديل كلمة السر الخاصة بك بنجاح',
    password_digits: 'يجب ان تحتوي كلمة السر على ٦ خانات على الاقل',
    current_pass_err: 'كلمة السر الحالية غير صحيحة',
    birth_date: 'تاريخ الميلاد',
    gender: {
      gender: 'الجنس',
      unspecified: 'غير محدد',
      male: 'ذكر',
      female: 'أنثى',
      rather_not_say: 'أفضل عدم القول'
    },
    activated_ttl: 'تم تفعيل حسابك بنجاح',
    activated_msg: 'تم تفعيل حساب سمارت ڤووم الخاص بك بنجاح',
    activation_email_sent: 'لقد قمنا بإرسال رسالة إلى',
    activation_email_explained: 'من فضلك قم بالتحقق من البريد ليتم تفعيل حسابك.',
    resend_email: 'اعادة الإرسال',
    my_accounts: 'حساباتي'
  },
}
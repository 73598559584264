<template>
  <div></div>
</template>

<script>
  export default {
    methods: {
      installZoom () {
        window.location.replace('https://zoom.us/oauth/authorize?response_type=code&client_id=H7UNSW4pSYWv3OFGDkV8w&redirect_uri=https://web.smartvoom.com/zoom/meeting/')
      },
    },
    created () {
      this.installZoom()
    }
  }
</script>
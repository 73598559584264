<template>
  <v-container class="fill-height app-gradient" fluid>
    <loading-dialog v-model="isSVLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <success-snackbar v-model="isSuccess" :message="successMsg"></success-snackbar>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3">
        <v-card class="mt-2 elevation-12 pt-4 pb-4 ps-4 pe-4 rounded-xl" color="login_background">
          <v-card-text>
            <v-form @submit.prevent="confirmPasswordReset">
              <v-row justify="center" class="pb-0">
                <v-col cols="12" class="text-center">
                  <router-link to="/" tag="span" style="cursor: pointer">
                    <img :src="$vuetify.theme.dark ? require('@/assets/logo_white.png') : require('@/assets/logo.png')" 
                    align="middle" alt="SmartVoom" height="50" />
                  </router-link>
                  <div class="secondary--text font-20 mt-2">{{ form_title }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="new_password"
                    label="New Password"
                    type="password"
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    class="mt-3"
                    v-model="confirm_new_password"
                    label="Confirm New Password"
                    type="password"
                    outlined
                    rounded
                    dense
                    :rules="[passwords_match]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn 
                  class="textTransformNone"
                  color="primary" 
                  type="submit"
                  :disabled="isSVLoading || !form_valid"
                  block
                  rounded>
                    {{ $vuetify.lang.t('$vuetify.general.confirm') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import constants from '@/plugins/constants'
  const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  export default {
    data () {
      return {
        new_password: '',
        confirm_new_password: '',
        isSVLoading: false,
        isError: false,
        errorMessage: '',
        isSuccess: false,
        successMsg: ''
      }
    },
    props: ['uid', 'token'],
    computed: {
      form_title () {
        return 'Password Reset'
      },
      form_valid () {
        return this.new_password.length > 0 && this.passwords_match === true
      },
      passwords_match () {
        if (this.new_password !== this.confirm_new_password) {
          return this.$vuetify.lang.t('$vuetify.login_signup.pass_no_match')
        }
        return true
      },
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      }
    },
    methods: {
      resetForm () {
        this.formType = 'check'
      },
      confirmPasswordReset () {
        if (!this.form_valid) {
          return
        }
        var params = {
          uid: this.uid,
          token: this.token,
          new_password: this.new_password,
          confirm_new_password: this.confirm_new_password,
        }
        this.isSVLoading = true
        this.$http.post(this.main_base_url + '/auth/users/confirm_password_reset/', params, this.main_api_key_header).then(response => {
          this.isSVLoading = false
          this.successMsg = this.$vuetify.lang.t('$vuetify.account.password_success')
          this.isSuccess = true
          setTimeout(() => {
            this.$router.push('/')
          }, 1000)
        }, error => {
          this.isSVLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
          console.log(error)
        })
      },
      setTitle () {
        document.title = 'Password Reset | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.setTitle()
    }
  }
</script>
<template>
  <v-main>
    <v-app-bar color="white" flat app style="z-index: 100;">
      <v-toolbar class="mx-auto transparent" flat :style="'max-width: ' + maxPageWidth + ';'">
        <v-toolbar-title>
          <router-link to="/creator" tag="span" style="cursor: pointer">
            <img src="@/assets/logo.png" 
            align="middle" alt="SmartVoom" :height="$vuetify.breakpoint.smAndUp ? 40 : 28" />
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-row align="center" justify="end">
          <v-menu offset-y class="text-center" close-on-content-click :nudge-width="100" :max-height="500">
            <template v-slot:activator="{ on }">
             <v-btn v-on="on" class="me-2" small icon>
                <v-icon>mdi-web</v-icon>
             </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group v-model="current_lang">
                <v-list-item value="en">
                  <v-list-item-content>
                    <v-list-item-title class="font-14 bold">
                      English
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item value="ar" class="mt-1">
                  <v-list-item-content>
                    <v-list-item-title class="font-14 bold">
                      العربية
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <logged-menu v-if="$store.getters.token !== null" :dark-mode-enabled="false"></logged-menu>
          <v-btn rounded color="primary" to="/login" v-else>
            {{ $vuetify.lang.t('$vuetify.login_signup.sign_in') }}
          </v-btn>
        </v-row>
      </v-toolbar>
    </v-app-bar>
  </v-main>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    computed: {
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.updateUserPreferences({default_language: value})
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      },
    }
  }
</script>
import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

import moment from 'moment'
Vue.prototype.moment = moment

import vBlur from 'v-blur'
Vue.use(vBlur)

import VueChatScroll from 'vue-chat-scroll'
Vue.use(VueChatScroll)

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('7d')

import VueExcelEditor from 'vue-excel-editor'
Vue.use(VueExcelEditor)

import VueCountdown from '@chenfengyuan/vue-countdown'
Vue.component(VueCountdown.name, VueCountdown)

require('../../../../node_modules/@vime/core/themes/default.css')
require('../../../../node_modules/@vime/core/themes/light.css')

<template>
  <div>
    <loading-dialog v-model="isLoading"></loading-dialog>
  </div>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    data () {
      return {
        isLoading: false
      }
    },
    computed: {
      code () {
        if (this.$route.query.code === undefined) {
          return null
        }
        return this.$route.query.code
      },
      platform () {
        if (this.$route.query.state === undefined) {
          return null
        }
        return this.$route.query.state.split(',')[0]
      },
      voom_id () {
        return this.$route.query.state.split(',')[1]
      }
    },
    methods: {
      getPlatformUrl () {
        this.isLoading = true
        this.$http.get(this.main_base_url + '/creators_api/ed_teams/custom_domain/' + this.platform + '/', this.main_api_key_header).then(response => {
          this.isLoading = false
          window.location.href = response.body.team_url + '/creator/zoom/meeting/?code=' + this.code + '&state=' + this.voom_id
        }, error => {
          this.isLoading = false
          this.$router.push('/')
        })
      }
    },
    created () {
      if (this.code === null || this.platform === null || this.voom_id === null) {
        this.$router.push('/')
        return
      }
      if (this.platform === 'smartvoom') {
        window.location.href = '/creator/zoom/meeting/?code=' + this.code + '&state=' + this.voom_id
      } else {
        this.getPlatformUrl()
      }
    }
  }
</script>
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { store } from './store'
import './plugins/general_plugins'
import './plugins/components'
import '@/plugins/root_mixin'
// import './plugins/firebase'

Vue.config.productionTip = false

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0)
  if (!store.state.isDataLoading) {
    next()
    return
  }

  store.watch(
    (state) => state.isDataLoading,
    (value) => {
      if (!value) {
        next()
      }
    }
  )
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

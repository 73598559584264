<template>
  <div :style="cssProps">
    <loading-dialog v-model="isLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <success-snackbar v-model="isSuccess" :message="successMsg"></success-snackbar>
    <video-viewer
      v-model="youtubeVideoDialog"
      :video-type="1"
      :src="youtube_video_id">
    </video-viewer>
    <div class="secondary" style="padding: 10px;" v-if="creatorPreview">
      <div class="text-center white--text font-15 weight-500">
        {{ $vuetify.lang.t('$vuetify.program_view.preview_text') }}
      </div>
    </div>
    <div class="program-card" v-if="voom !== null">
      <v-row class="fill-height mx-0" align="center">
        <v-container fluid>
          <v-card class="transparent elevation-0" :dark="!isLightProgramColor" :light="isLightProgramColor">
            <page-div>
              <div class="flex-item">
                <div v-if="voom.image !== null">
                  <v-avatar :size="120">
                    <img :src="voom.image.thumb_sm" />
                  </v-avatar>
                </div>
                <div :class="{'ms-4': voom.image !== null}">
                  <div class="font-30 weight-600">
                    {{ voom.name }}
                  </div>
                  <div class="font-20 weight-300">
                    {{ voom.short_description }}
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <v-chip color="transparent" dark
                  class="font-15"
                  v-if="voom.category_data.subcategory !== null || voom.category_data.category !== null">
                  <v-icon left>
                    mdi-shape
                  </v-icon>
                  {{ voom.category_data.category.name + (voom.category_data.subcategory !== null ? (' - ' + voom.category_data.subcategory.name) : '') }}
                </v-chip>
                <v-chip class="mx-2" label
                  :color="voom.is_enrollment_open ? 'green darken-1' : 'red darken-1'">
                  <v-icon left small>
                    {{ voom.is_enrollment_open ? 'mdi-lock-open-variant' : 'mdi-lock' }}
                  </v-icon>
                  {{ voom.is_enrollment_open ? $vuetify.lang.t('$vuetify.program_view.enrollment_open') : $vuetify.lang.t('$vuetify.program_view.enrollment_closed') }}
                </v-chip>
              </div>
              <v-list-item class="mt-2 px-0">
                <v-list-item-avatar :size="50" color="secondary">
                  <img v-if="voom.ed_team.image !== null" :src="voom.ed_team.image.thumb_sm" />
                  <span v-else class="font-25 white--text">
                    {{ voom.ed_team.name.substring(0, 1) }}
                  </span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-17 weight-600">
                    {{ voom.ed_team.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-btn
                v-if="voom.youtube_video_url !== null"
                class="mt-2 textTransformNone weight-500"
                outlined
                style="border: 3px solid currentColor;"
                large
                @click="watchVideo"
                >
                {{ $vuetify.lang.t('$vuetify.program_view.watch_video') }}
              </v-btn>
            </page-div>
          </v-card>
        </v-container>
      </v-row>
    </div>
    <v-container fluid v-if="voom !== null">
      <page-div>
        <v-row>
          <v-col cols="12" md="8">
            <div class="font-17 weight-600" v-if="voom.description !== null && voom.description.length > 0">
              {{ $vuetify.lang.t('$vuetify.program_view.description') }}
            </div>
            <div class="font-15 my-2" v-if="voom.description !== null">
              {{ (voom.description.length > 600 && !showFullDescription) ? voom.description.slice(0, 600) + '....' : voom.description }}
              <div>
                <v-btn text small class="textTransformNone" v-if="voom.description.length > 600" @click="showFullDescription = !showFullDescription">
                  {{ showFullDescription ? $vuetify.lang.t('$vuetify.program_view.show_less') : $vuetify.lang.t('$vuetify.program_view.show_more') }}
                </v-btn>
              </div>
            </div>
            <v-divider />
            <div class="mt-3 font-17 weight-600" v-if="voom.curriculum.length > 0">
              {{ $vuetify.lang.t('$vuetify.program_view.curriculum') }}
            </div>
            <v-timeline
              dense
              clipped
              v-if="voom.curriculum.length > 0">
              <v-timeline-item
                small
                v-for="(session, key) in voom.curriculum" :key="'voom_session-' + key">
                <v-card class="transparent elevation-0">
                  <div class="px-3 py-3">
                    <div class="font-18">
                      {{ $vuetify.lang.t('$vuetify.program_view.session') }} {{ key + 1 }}
                    </div>
                    <div class="font-20 weight-600">
                      {{ session.session_name }}
                    </div>
                    <div class="mt-2 font-16 text--secondary">
                      {{ session.session_description }}
                    </div>
                  </div>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
          <v-col cols="12" md="4">
            <div :class="{'mt-n15': $vuetify.breakpoint.mdAndUp}" v-if="voom.is_enrollment_open && !voom.is_user_enrolled">
              <v-card style="max-width: 400px;" class="mx-auto rounded-xl" outlined>
                <div class="py-4 px-4">
                  <v-list-item class="mt-2">
                    <v-list-item-avatar :size="50" color="secondary">
                      <img v-if="voom.instructor.user.image !== null" :src="voom.instructor.user.image.thumb_sm" />
                      <span v-else class="font-25 white--text">
                        {{ voom.instructor.name.substring(0, 1) }}
                      </span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="font-17 weight-500">
                        {{ voom.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-15">
                        {{ voom.instructor.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="mt-1 text-center weight-600 font-20" v-if="voom.start_date !== null && voom.end_date !== null">
                    {{ formatDate(voom.start_date, 'DD MMMM') }} - {{ formatDate(voom.end_date, 'DD MMMM') }}
                  </div>
                  <v-divider class="mt-2" />
                  <div class="mt-3" v-if="voom.final_enrollment_at !== null">
                    <countdown :time="remaining_time" ref="countdown" emit-events>
                      <template slot-scope="props">
                        <v-card outlined class="text-center">
                          <div class="px-3 pt-3 weight-500">
                            {{ $vuetify.lang.t('$vuetify.program_view.enrollment_closes_in') }}
                          </div>
                          <div class="mt-1 px-3 pb-3 flex-item" style="justify-content: center;">
                            <div>
                              <div>
                                {{ props.days }}
                              </div>
                              <div class="font-15 weight-600">
                                {{ $vuetify.lang.t('$vuetify.program_view.days') }}
                              </div>
                            </div>
                            <div class="ms-3">
                              <div>
                                {{ props.hours }}
                              </div>
                              <div class="font-15 weight-600">
                                {{ $vuetify.lang.t('$vuetify.program_view.hours') }}
                              </div>
                            </div>
                            <div class="ms-3">
                              <div>
                                {{ props.minutes }}
                              </div>
                              <div class="font-15 weight-600">
                                {{ $vuetify.lang.t('$vuetify.program_view.minutes') }}
                              </div>
                            </div>
                            <div class="ms-3">
                              <div>
                                {{ props.seconds }}
                              </div>
                              <div class="font-15 weight-600">
                                {{ $vuetify.lang.t('$vuetify.program_view.seconds') }}
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </template>
                    </countdown>
                  </div>
                  <div class="mt-3 text-center weight-600 font-25">
                    {{ voom.pricing_type === 0 ? $vuetify.lang.t('$vuetify.program_view.free') : priceFormatter.format(voom.price) }}
                  </div>
                  <div class="mt-4" v-if="(!isLoggedIn && !isPaying) || creatorPreview">
                    <v-text-field
                      :label="$vuetify.lang.t('$vuetify.login_signup.first_name')"
                      v-model="user_info_obj.first_name"
                      :disabled="creatorPreview"
                      outlined
                      hide-details
                      dense
                      rounded>
                    </v-text-field>
                    <v-text-field
                      class="mt-3"
                      :label="$vuetify.lang.t('$vuetify.login_signup.last_name')"
                      v-model="user_info_obj.last_name"
                      :disabled="creatorPreview"
                      outlined
                      hide-details
                      dense
                      rounded>
                    </v-text-field>
                    <v-text-field
                      class="mt-3"
                      :label="$vuetify.lang.t('$vuetify.login_signup.email')"
                      v-model="user_info_obj.email"
                      :disabled="creatorPreview"
                      type="email"
                      outlined
                      hide-details
                      dense
                      rounded>
                    </v-text-field>
                    <v-text-field
                      class="mt-3"
                      :label="$vuetify.lang.t('$vuetify.login_signup.password')"
                      v-model="user_info_obj.password"
                      :disabled="creatorPreview"
                      type="password"
                      outlined
                      hide-details
                      dense
                      rounded>
                    </v-text-field>
                    <v-text-field
                      class="mt-3"
                      v-model="user_info_obj.confirm_password"
                      :disabled="creatorPreview"
                      :label="$vuetify.lang.t('$vuetify.login_signup.confirm_password')"
                      type="password"
                      rounded
                      :rules="[passwords_match]"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="mt-5 text-center" v-if="!isPaying">
                    <v-btn
                      class="textTransformNone"
                      color="secondary"
                      :disabled="!userInfoValid || creatorPreview"
                      @click="enrollNow">
                      {{ $vuetify.lang.t('$vuetify.program_view.enroll_now') }}
                    </v-btn>
                  </div>
                  <form v-show="isPaying" id="payment-form" class="mt-2"
                    style="border: 2px solid grey; padding: 10px;">
                  </form>
                  <div class="mt-2 text-center" v-if="isPaying">
                    <v-btn
                      class="textTransformNone"
                      color="secondary"
                      :disabled="!isCardReady"
                      @click="payNow">
                      {{ $vuetify.lang.t('$vuetify.program_view.pay_now') }}
                    </v-btn>
                  </div>
                  <v-divider class="mt-2" />
                  <div class="mt-3">
                    <div v-for="(outcome, key) in voom.outcomes" :key="'outcome-' + key"
                      class="flex-item">
                      <div>
                        <v-icon :color="programColor">
                          mdi-check
                        </v-icon>
                      </div>
                      <div class="font-16 ms-3">
                        {{ outcome.outcome }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </page-div>
    </v-container>
  </div>
</template>

<script>
  import '@stripe/stripe-js'
  export default {
    data () {
      return {
        isLoading: false,
        priceFormatter: null,
        voom: null,
        youtubeVideoDialog: false,
        youtube_video_id: null,
        showFullDescription: false,
        stripe: null,
        paymentIntent: null,
        cardElement: null,
        isPaying: false,
        isCardReady: false,
        user_info_obj: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          confirm_password: '',
        },
        isError: false,
        errorMessage: '',
        isSuccess: false,
        successMsg: ''
      }
    },
    props: ['pid'],
    watch: {
      youtubeVideoDialog (value) {
        if (!value) {
          this.youtube_video_id = null
        }
      }
    },
    computed: {
      creatorPreview () {
        if (this.voom !== null) {
          return this.voom.is_creator_preview
        }
        return false
      },
      cssProps () {
        return {
          '--program-color': this.programColor,
        }
      },
      programColor () {
        return (this.voom !== null && this.voom.voom_color !== null) ? this.voom.voom_color : this.$vuetify.theme.themes.light.primary
      },
      isLightProgramColor () {
        let color = (this.voom !== null && this.voom.voom_color !== null) ? this.voom.voom_color : this.$vuetify.theme.themes.light.primary
        return this.isLightHex(color)
      },
      stripe_color () {
        return this.$vuetify.theme.dark ? '#fff' : '#000'
      },
      isLoggedIn () {
        return this.$store.getters.token !== null
      },
      userInfoValid () {
        if (this.isLoggedIn) {
          return true
        }
        var isValid = this.user_info_obj.first_name.length > 0 && this.user_info_obj.last_name.length > 0 && this.user_info_obj.email.length > 0 && this.user_info_obj.password.length > 0 && (this.user_info_obj.password === this.user_info_obj.confirm_password)
        return isValid
      },
      passwords_match () {
        if (this.user_info_obj.password !== this.user_info_obj.confirm_password) {
          return this.$vuetify.lang.t('$vuetify.login_signup.pass_no_match')
        }
        return true
      },
      remaining_time () {
        const moment = require('moment')
        const current_time = moment.utc()
        const final_enrollment_at = moment.utc(this.voom.final_enrollment_at)
        var remaining = final_enrollment_at - current_time
        return remaining
      },
    },
    methods: {
      isLightHex (color) {
        const hex = color.replace('#', '')
        const c_r = parseInt(hex.substr(0, 2), 16)
        const c_g = parseInt(hex.substr(2, 2), 16)
        const c_b = parseInt(hex.substr(4, 2), 16)
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000
        return brightness > 155
      },
      getUserInfoObj () {
        var data = Object.assign({}, this.user_info_obj)
        data.name = data.first_name.trim() + ' ' + data.last_name.trim()
        delete data.first_name
        delete data.last_name
        return data
      },
      initStripeElements () {
        if (this.paymentIntent === null){
          return
        }
        this.stripe = Stripe(
          process.env.VUE_APP_STRIPE_PUB_KEY, {
            locale: this.$store.getters.lang,
            stripeAccount: this.paymentIntent.stripe_account
          }
        )
        this.isPaying = true
        let elements = this.stripe.elements()
        this.cardElement = elements.create('card', {
          hidePostalCode: true,
          style: {
            base: {
              lineHeight: '30px',
              fontWeight: 400,
              fontSize: '17px',
              fontFamily: "'Quicksand', 'Tajawal'",
              color: this.stripe_color
            }
          }
        })
        this.cardElement.mount('#payment-form')

        this.cardElement.on('change', (event) => {
          if (event.complete) {
            this.isCardReady = true
          } else if (event.error) {
            this.isCardReady = false
          }
        })
      },
      payNow () {
        if (!this.isPaying || !this.isCardReady) {
          return
        }
        this.isLoading = true
        this.stripe.confirmCardPayment(this.paymentIntent.client_secret, {
          payment_method: {
            card: this.cardElement
          }
        })
        .then((result) => {
          this.isLoading = false
          if (result.error) {
            this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
            this.isError = true
          } else {
            this.successMsg = 'You have successfully enrolled in ' + this.voom.name
            this.isSuccess = true
            setTimeout(() => {
              this.$router.push('/')
            }, 1500)
          }
        })
      },
      watchVideo () {
        this.youtube_video_id = this.voom.youtube_video_url
        this.youtubeVideoDialog = true
      },
      setPageTitle () {
        document.title = this.voom.name + ' | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      },
      getVoom () {
        this.isLoading = true
        var auth_header = null
        if (this.isLoggedIn) {
          auth_header = {
            headers: {
              Authorization: 'Token ' + this.$store.getters.token,
            }
          }
        } else {
          auth_header = this.api_key_header
        }
        var query = ''
        if (process.env.VUE_APP_CUSTOM_DOMAIN === 'true') {
          query = '?ed_team_id=' + process.env.VUE_APP_ED_TEAM_ID
        }
        this.$http.get(this.basic_url + '/generic/programs/' + this.pid + '/' + query, auth_header).then(response => {
          this.isLoading = false
          this.voom = response.body
          this.setPageTitle()
        }, error => {
          this.isLoading = false
          this.$router.push('/')
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      enrollNow () {
        if (this.voom.pricing_type === 0) {
          this.freeEnrollment()
        } else {
          this.initializePayment()
        }
      },
      freeEnrollment () {
        this.isLoading = true
        var auth_header = null
        if (this.isLoggedIn) {
          auth_header = {
            headers: {
              Authorization: 'Token ' + this.$store.getters.token,
            }
          }
        } else {
          auth_header = this.api_key_header
        }
        var data = {}
        if (!this.isLoggedIn) {
          data = this.getUserInfoObj()
        }
        data.voom = this.voom.id
        this.$http.post(this.basic_url + '/generic/programs/free_enrollment/', data, auth_header).then(response => {
          this.isLoading = false
          this.successMsg = this.$vuetify.lang.t('$vuetify.program_view.success_enrolled') + ' ' + this.voom.name
          this.isSuccess = true
          setTimeout(() => {
            this.$router.push('/')
          }, 1500)
        }, error => {
          this.isLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      initializePayment () {
        this.isLoading = true
        var auth_header = null
        if (this.isLoggedIn) {
          auth_header = {
            headers: {
              Authorization: 'Token ' + this.$store.getters.token,
            }
          }
        } else {
          auth_header = this.api_key_header
        }
        var data = {}
        if (!this.isLoggedIn) {
          data = this.getUserInfoObj()
        }
        data.voom = this.voom.id
        this.$http.post(this.basic_url + '/generic/programs/initialize_payment/', data, auth_header).then(response => {
          this.isLoading = false
          this.paymentIntent = response.body
          this.initStripeElements()
        }, error => {
          this.isLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      }
    },
    created () {
      this.priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      this.getVoom()
    }
  }
</script>

<style scoped>
  .program-card {
    background: var(--program-color);
    min-height: 400px;
    padding: 15px;
  }

  .flex-item {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

</style>
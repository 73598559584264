<template>
  <v-container class="fill-height primary" fluid>
    <loading-dialog v-model="isSVLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-menu close-on-content-click>
          <template v-slot:activator="{ on }">
           <v-btn v-on="on" class="me-2" text dark>
              <v-icon left>mdi-web</v-icon>
              {{ current_lang === 'en' ? 'English' : 'العربية' }}
              <v-icon right>mdi-chevron-down</v-icon>
           </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group v-model="current_lang">
              <v-list-item value="en">
                <v-list-item-content>
                  <v-list-item-title class="font-14 bold">
                    English
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="ar" class="mt-1">
                <v-list-item-content>
                  <v-list-item-title class="font-14 bold">
                    العربية
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-card class="mt-2 elevation-12 pt-4 pb-4 ps-4 pe-4 rounded-xl" color="login_background">
          <v-card-text>
            <v-form @submit.prevent="submitForm">
              <v-row justify="center" class="pb-0">
                <v-col cols="12" class="text-center">
                  <a :href="constants.SV_CMS_URL" tag="span" style="cursor: pointer">
                    <img :src="$vuetify.theme.dark ? require('@/assets/smartvoom_logo_white.png') : require('@/assets/logo.png')" 
                    align="middle" alt="SmartVoom" height="50" />
                  </a>
                  <div class="secondary--text font-20 mt-2">{{ form_title }}</div>
                </v-col>
                <v-col cols="12" v-if="formType === 'check'">
                  <v-text-field
                    v-model="login_name"
                    :label="login_field === 'email' ? $vuetify.lang.t('$vuetify.login_signup.email') : $vuetify.lang.t('$vuetify.login_signup.username')"
                    type="text"
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-else-if="formType === 'login'">
                  <v-btn 
                    class="textTransformNone"
                    @click="resetForm" 
                    block
                    rounded
                    outlined>
                    {{ login_name }}
                  </v-btn>
                  <v-text-field
                    class="mt-3"
                    v-model="password"
                    :label="$vuetify.lang.t('$vuetify.login_signup.password')"
                    type="password"
                    outlined
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn 
                  class="textTransformNone"
                  color="primary" 
                  type="submit"
                  :disabled="isSVLoading || !form_valid"
                  block
                  rounded>
                    {{ $vuetify.lang.t('$vuetify.login_signup.continue') }}
                  </v-btn>
                  <div class="font-16 text-center mt-3" v-if="!is_custom_domain || login_field === 'email'">
                    <a
                      style="text-decoration: underline;"
                      class="font-15"
                      @click.prevent="$router.push('/forgot-password')">
                      {{ $vuetify.lang.t('$vuetify.login_signup.forgot_password') }}
                    </a>
                  </div>
                  <div class="mt-2 text-center font-16 weight-600" v-if="!is_custom_domain">
                    {{ $vuetify.lang.t('$vuetify.login_signup.no_account_question') }}
                    <a
                      style="text-decoration: underline;"
                      class="font-15"
                      @click.prevent="$router.push('/signup')">
                      {{ $vuetify.lang.t('$vuetify.pages.sign_up') }}
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <div class="mt-5 text-center white--text" style="direction: ltr !important;" v-if="!is_custom_domain">
          All rights reserved.
          <span
           class="white--text weight-600" >
            SmartVoom, Inc.
          </span>
        </div>
        <div class="mt-5 text-center white--text" style="direction: ltr !important;" v-else>
          Powered by
          <span
           class="white--text weight-600" >
            <a class="white--text" target="_blank" :href="constants.SV_CMS_URL">SmartVoom</a>.
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    data () {
      return {
        login_name: '',
        password: '',
        formType: 'check',
        constants: null,
        isSVLoading: false,
        isError: false,
        errorMessage: ''
      }
    },
    computed: {
      form_title () {
        return this.$vuetify.lang.t('$vuetify.login_signup.sign_in')
      },
      login_field () {
        if (this.is_custom_domain) {
          return process.env.VUE_APP_LOGIN_FIELD
        }
        return 'email'
      },
      form_valid () {
        if (this.formType === 'check') {
          return this.login_name.length > 0
        } else if (this.formType === 'login') {
          var isValid = this.password.length > 0
          isValid = isValid && this.login_name.length > 0
          return isValid
        }
        return false
      },
      student_categorization_enabled () {
        if (this.$store.getters.conf === null) {
          return false
        }
        return this.$store.getters.conf.student_categorization_enabled === '1'
      },
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      },
      redirection_type () {
        if (this.$route.query.redirection_type !== undefined) {
          return this.$route.query.redirection_type
        }
        return 'normal'
      },
      activation_uid () {
        if (this.$route.query.activation_uid !== undefined) {
          return this.$route.query.activation_uid
        }
        return null
      },
      activation_token () {
        if (this.$route.query.activation_token !== undefined) {
          return this.$route.query.activation_token
        }
        return null
      }
    },
    methods: {
      submitForm () {
        if (this.formType === 'check') {
          this.checkEmail()
        } else {
          this.loginUser()
        }
      },
      resetForm () {
        this.formType = 'check'
      },
      checkEmail () {
        var query = '?username=' + encodeURI(this.login_name.toLowerCase())
        if (this.is_custom_domain) {
          query += '&ed_team_id=' + this.ed_team_id
        }
        this.isSVLoading = true
        this.$http.get(this.main_base_url + '/auth/users/check/' + query, this.main_api_key_header).then(response => {
          this.isSVLoading = false
          if (response.body.user_exists) {
            this.formType = 'login'  
          } else {
            this.errorMessage = this.$vuetify.lang.t('$vuetify.login_signup.no_account')
            this.isError = true
          }
        }, error => {
          this.isSVLoading = false
        })
      },
      loginUser () {
        const params = {
          password: this.password,
          notif_device_token: this.$store.getters.notif_token,
          locale: this.$store.getters.lang,
          username: this.login_name
        }
        if (this.is_custom_domain) {
          params.ed_team = this.ed_team_id
        }
        this.isSVLoading = true
        this.$http.post(this.main_base_url + '/auth/users/login/', params, this.main_api_key_header).then(response => {
          this.isSVLoading = false
          this.$cookies.set(constants.cookies.TOKEN, response.body.access_token, null, null, constants.COOKIES_DOMAIN_NAME)
          this.$cookies.set(constants.cookies.SESSION_ID, response.body.session_id, null, null, constants.COOKIES_DOMAIN_NAME)
          this.$cookies.set(constants.cookies.LANG, response.body.user.user_preferences.default_language, null, null, constants.COOKIES_DOMAIN_NAME)
          if (response.body.user.user_type === 1) {
            this.$cookies.set(constants.cookies.ED_ACCOUNT_TYPE, response.body.user.account_type, null, null, constants.COOKIES_DOMAIN_NAME)
          }
          if (this.redirection_type === 'activate_account') {
            window.location.href = '/account/activate/' + this.activation_uid + '/' + this.activation_token
          } else {
            if (response.body.user.user_type === 1) {
              window.location.href = '/'
            } else {
              window.location.href = '/user/accounts/?action=select_account'
            }
          }
        }, error => {
          this.isSVLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.login_signup.unable_to_login')
          this.isError = true
        })
      },
      setTitle () {
        document.title = this.$vuetify.lang.t('$vuetify.login_signup.sign_in') + ' | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.constants = constants
      this.setTitle()
    }
  }
</script>

<style>
  .or-span:after,
  .or-span:before{
      content:"\00a0\00a0\00a0\00a0\00a0";
      text-decoration:line-through;
  }
</style>
<template>
  <v-container class="fill-height app-gradient" fluid>
    <loading-dialog v-model="isSVLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <success-snackbar v-model="isSuccess" :message="successMsg"></success-snackbar>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-menu close-on-content-click>
          <template v-slot:activator="{ on }">
           <v-btn v-on="on" class="me-2" text dark>
              <v-icon left>mdi-web</v-icon>
              {{ current_lang === 'en' ? 'English' : 'العربية' }}
              <v-icon right>mdi-chevron-down</v-icon>
           </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group v-model="current_lang">
              <v-list-item value="en">
                <v-list-item-content>
                  <v-list-item-title class="font-14 bold">
                    English
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="ar" class="mt-1">
                <v-list-item-content>
                  <v-list-item-title class="font-14 bold">
                    العربية
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-card class="mt-2 elevation-12 pt-4 pb-4 ps-4 pe-4 rounded-xl" color="login_background">
          <v-card-text>
            <v-form @submit.prevent="sendPasswordResetRequest">
              <v-row justify="center" class="pb-0">
                <v-col cols="12" class="text-center">
                  <router-link to="/" tag="span" style="cursor: pointer">
                    <img :src="$vuetify.theme.dark ? require('@/assets/logo_white.png') : require('@/assets/logo.png')" 
                    align="middle" alt="SmartVoom" height="50" />
                  </router-link>
                  <div class="secondary--text font-20 mt-2">{{ form_title }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    :label="$vuetify.lang.t('$vuetify.login_signup.email')"
                    type="email"
                    rounded
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn 
                  class="textTransformNone"
                  color="primary" 
                  type="submit"
                  :disabled="isSVLoading || !form_valid"
                  block
                  rounded>
                    {{ $vuetify.lang.t('$vuetify.login_signup.continue') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pt-0 text-center">
                  <div class="font-16 weight-600">
                    {{ $vuetify.lang.t('$vuetify.login_signup.have_an_account_question') }}
                    <a
                      style="text-decoration: underline;"
                      class="font-15"
                      @click.prevent="$router.push('/login')">
                      {{ $vuetify.lang.t('$vuetify.pages.login') }}
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import constants from '@/plugins/constants'
  const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  export default {
    data () {
      return {
        email: '',
        isSVLoading: false,
        isError: false,
        errorMessage: '',
        isSuccess: false,
        successMsg: ''
      }
    },
    computed: {
      form_title () {
        return this.$vuetify.lang.t('$vuetify.login_signup.forgot_password')
      },
      form_valid () {
        return this.email.length > 0
      },
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      }
    },
    methods: {
      resetForm () {
        this.formType = 'check'
      },
      sendPasswordResetRequest () {
        if (this.email.length === 0) {
          return
        }
        let params = {
          email: this.email
        }
        if (this.is_custom_domain) {
          params.ed_team = this.ed_team_id
        }
        this.isSVLoading = true
        this.$http.post(this.main_base_url + '/auth/users/reset_password/', params, this.main_api_key_header).then(response => {
          this.isSVLoading = false
          this.successMsg = 'We have sent an email to ' + this.email + ' along with instructions to reset your password.'
          this.isSuccess = true
        }, error => {
          this.isSVLoading = false
          if (error.status === 400) {
            this.errorMessage = this.$vuetify.lang.t('$vuetify.login_signup.no_account')
            this.isError = true
            return
          }
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.isError = true
        })
      },
      setTitle () {
        document.title = 'Forgot Password | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.setTitle()
    }
  }
</script>
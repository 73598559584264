<template>
  <div></div>
</template>

<script>
  export default {
    created () {
      if (this.$store.state.user.user_type === 0) {
        window.location.href = '/user/accounts/?action=select_account'
      } else {
        if (this.$store.state.user.account_type === 0) {
          window.location.href = '/learning'
        } else {
          window.location.href = '/creator'
        }
      }
    }
  }
</script>
import en from 'vuetify/es5/locale/en'

export default {
  ...en,
  accounts: {
    choose_account: 'Choose an Account',
    creator: 'Admin / Instructor',
    student: 'Learner',
    create_vs: 'Create a Virtual School',
    school_name: 'School Name',
    invitation: '(Invitation)',
    accept: 'Accept',
    decline: 'Decline'
  },
  pages: {
    login: 'Login',
    sign_up: 'Sign Up'
  },
  general: {
    confirm: 'Confirm',
    general_error: 'Something wrong happened, please try again later',
    app_name: 'SmartVoom',
    loading: 'Loading...',
    back_home: 'Back to Home',
  },
  login_signup: {
    sign_up: 'Sign Up',
    sign_in: 'Sign In',
    email: 'Email',
    username_or_email: 'Email',
    password: 'Password',
    first_name: 'First Name',
    last_name: 'Last Name',
    confirm_password: 'Confirm Password',
    continue: 'Continue',
    pass_no_match: 'Passwords do not match',
    no_account: 'This account does not exist',
    unable_to_login: 'Unable to login with the provided credentials',
    category: 'Category',
    subcategory: 'Subcategory',
    username: 'Username',
    no_account_question: 'Don\'t have an account?',
    have_an_account_question: 'Already have an account?',
    team_name: 'Name',
    create_first_team: 'Create Your First Educational Team',
    create_team: 'Create an Educational Team',
    account_activation: 'Account Activation',
    continue_facebook: 'Continue With Facebook',
    forgot_password: 'Forgot Password?',
    or: 'OR',
    fb_error: 'Something wrong happened in the process of connecting to Facebook'
  },
  program_view: {
    preview_text: 'This is a preview, as an admin / instructor you will not be able to enroll.',
    enrollment_open: 'Enrollment Open',
    enrollment_closed: 'Enrollment Closed',
    description: 'Description',
    curriculum: 'Curriculum',
    session: 'Session',
    free: 'FREE',
    enrollment_closes_in: 'Enrollment Closes In',
    watch_video: 'Watch Video',
    show_more: 'Show More',
    show_less: 'Show Less',
    enroll_now: 'Enroll Now',
    pay_now: 'Pay Now',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    success_enrolled: 'You have successfully enrolled in'
  },
  account: {
    view_profile: 'View My Profile',
    wallet: 'Wallet',
    logout: 'Logout',
    profile: 'Profile',
    your_name: 'Your name',
    change_password: 'Change Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_new_password: 'Confirm New Password',
    profile_success: 'Your profile was updated successfully',
    password_success: 'Your password was changed successfully',
    password_digits: 'A Password must be of at least 6 digits',
    current_pass_err: 'Incorrect current password',
    birth_date: 'Birth Date',
    gender: {
      gender: 'Gender',
      unspecified: 'Unspecified',
      male: 'Male',
      female: 'Female',
      rather_not_say: 'Rather not say'
    },
    activated_ttl: 'Account Activated Successfully',
    activated_msg: 'Your SmartVoom account has been activated successfully.',
    activation_email_sent: 'We\'ve sent an email to',
    activation_email_explained: 'Open it up to activate your account.',
    resend_email: 'Resend Email',
    my_accounts: 'My Accounts'
  },
}
<template>
  <v-container class="fill-height app-gradient" fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-menu close-on-content-click>
          <template v-slot:activator="{ on }">
           <v-btn v-on="on" class="me-2" text dark>
              <v-icon left>mdi-web</v-icon>
              {{ current_lang === 'en' ? 'English' : 'العربية' }}
              <v-icon right>mdi-chevron-down</v-icon>
           </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group v-model="current_lang">
              <v-list-item value="en">
                <v-list-item-content>
                  <v-list-item-title class="font-14">
                    English
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="ar" class="mt-1">
                <v-list-item-content>
                  <v-list-item-title class="font-14">
                    العربية
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-card class="mt-2 elevation-12 pt-2 pb-2 ps-2 pe-2" color="login_background">
          <v-card-text>
            <div class="text-center">
              <router-link to="/" tag="span" style="cursor: pointer">
                <img src="@/assets/logo.png" 
                align="middle" alt="SmartVoom" height="50" />
              </router-link>
              <div class="secondary--text font-20 mt-2">
                {{ $vuetify.lang.t('$vuetify.accounts.choose_account') }}
              </div>
            </div>
            <div class="mt-2">
              <v-list>
                <v-list-item v-for="(account, key) in user_accounts"
                  :key="key"
                  :three-line="!account.is_invitation_accepted"
                  @click="chooseAccount(account)">
                  <v-list-item-avatar :size="70" color="primary">
                    <span class="white--text font-25" v-if="account.ed_team.image === null">
                      {{ account.ed_team.name.substring(0, 1) }}
                    </span>
                    <img v-else :src="account.ed_team.image.thumb_sm" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-16 weight-300">
                      {{ account.ed_team.name + (!account.is_invitation_accepted ? ' ' + $vuetify.lang.t('$vuetify.accounts.invitation') : '') }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-14">
                      <v-chip small>
                        {{ account.account_type === 0 ? $vuetify.lang.t('$vuetify.accounts.student') : $vuetify.lang.t('$vuetify.accounts.creator') }}
                      </v-chip>
                    </v-list-item-subtitle>
                    <div v-if="!account.is_invitation_accepted">
                      <v-btn
                        x-small
                        outlined
                        color="red darken-1"
                        @click="acceptDeleteInvitation(0, account)">
                        {{ $vuetify.lang.t('$vuetify.accounts.decline') }}
                      </v-btn>
                      <v-btn
                        class="ms-1"
                        x-small
                        outlined
                        @click="acceptDeleteInvitation(1, account)">
                        {{ $vuetify.lang.t('$vuetify.accounts.accept') }}
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <div class="mt-2">
              <v-btn
                class="textTransformNone"
                color="secondary"
                @click="$router.push('/vs/create')"
                block
                outlined
                rounded>
                <v-icon left>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.accounts.create_vs') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    data () {
      return {
        isLoading: false,
        user_accounts: [],
        isError: false,
        errorMessage: ''
      }
    },
    computed: {
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      },
      action () {
        if (this.$route.query.action !== undefined) {
          return this.$route.query.action
        }
        return null
      }
    },
    methods: {
      acceptDeleteInvitation (accept_invitation, account) {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        var data = {
          accept_invitation: accept_invitation,
          account_id: account.id
        }
        this.$http.post(this.basic_url + '/generic/account_invitation/', data, auth_header).then(response => {
          this.isLoading = false
          this.getUserAccounts()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      getUserAccounts () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        this.$http.get(this.basic_url + '/generic/user_accounts/', auth_header).then(response => {
          this.isLoading = false
          this.user_accounts = response.body
          this.accountSelectionAction()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            this.$store.dispatch('setUnauthorizedDialog', true)
          }
        })
      },
      chooseAccount (account) {
        if (!account.is_invitation_accepted) {
          return
        }
        this.$cookies.set(constants.cookies.CURRENT_ACCOUNT_ID, account.id, null, null, constants.COOKIES_DOMAIN_NAME)
        if (account.account_type === 0) {
          this.$cookies.set(constants.cookies.ED_ACCOUNT_TYPE, 0, null, null, constants.COOKIES_DOMAIN_NAME)
          window.location.href = '/learning'
        } else {
          this.$cookies.set(constants.cookies.ED_ACCOUNT_TYPE, 1, null, null, constants.COOKIES_DOMAIN_NAME)
          window.location.href = '/creator'
        }
      },
      accountSelectionAction () {
        if (this.$store.getters.user === null) {
          return
        }
        let accountsCount = this.user_accounts.length
        if (this.action !== 'wait') {
          if (accountsCount === 0) {
            this.$router.push('/vs/create')
          }
        }
        if (this.action === 'select_account') {
          if (accountsCount === 1) {
            this.chooseAccount(this.user_accounts[0])
          }
        }
      },
      setTitle () {
        document.title = this.$vuetify.lang.t('$vuetify.accounts.choose_account') + ' | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.setTitle()
      this.getUserAccounts()
    }
  }
</script>
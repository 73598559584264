<template>
  <v-container class="fill-height" fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <error-snackbar v-model="errorSnackbar" :errorMessage="errorMessage"></error-snackbar>
    <v-row align="center" justify="center" class="fill-height">
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
      >
        <v-menu close-on-content-click>
          <template v-slot:activator="{ on }">
           <v-btn v-on="on" class="me-2" text>
              <v-icon left>mdi-web</v-icon>
              {{ current_lang === 'en' ? 'English' : 'العربية' }}
              <v-icon right>mdi-chevron-down</v-icon>
           </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group v-model="current_lang">
              <v-list-item value="en">
                <v-list-item-content>
                  <v-list-item-title class="font-14">
                    English
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item value="ar" class="mt-1">
                <v-list-item-content>
                  <v-list-item-title class="font-14">
                    العربية
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-card class="mt-2 elevation-12 pt-4 pb-4 ps-4 pe-4" color="card_color">
          <v-card-text>
            <v-form @submit.prevent="createVS">
              <v-row justify="center" class="pb-0">
                <v-col cols="12" class="text-center">
                  <router-link to="/" tag="span" style="cursor: pointer">
                    <img :src="$vuetify.theme.dark ? require('@/assets/smartvoom_logo_white.png') : require('@/assets/smartvoom_logo.png')" 
                    align="middle" alt="SmartVoom" height="50" />
                  </router-link>
                  <div class="secondary--text font-20 mt-2">{{ form_title }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="school_name"
                    :label="$vuetify.lang.t('$vuetify.accounts.school_name')"
                    type="text"
                    rounded
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn 
                  class="textTransformNone"
                  color="primary" 
                  type="submit"
                  :disabled="isLoading || school_name.length === 0"
                  block
                  rounded>
                    {{ $vuetify.lang.t('$vuetify.general.confirm') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import constants from '@/plugins/constants'
  export default {
    data () {
      return {
        isLoading: false,
        errorSnackbar: false,
        errorMessage: '',
        school_name: ''
      }
    },
    computed: {
      form_title () {
        return this.$vuetify.lang.t('$vuetify.accounts.create_vs')
      },
      current_lang: {
        get () {
          return this.$store.getters.lang
        },
        set (value) {
          this.$cookies.set(constants.cookies.LANG, value, null, null, constants.COOKIES_DOMAIN_NAME)
          location.reload()
        }
      }
    },
    methods: {
      createVS () {
        if (this.school_name.length === 0) {
          return
        }
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token,
          }
        }
        this.isLoading = true
        this.$http.post(this.basic_url + '/generic/create_vs/', {name: this.school_name}, auth_header).then(response => {
          this.isLoading = false
          this.$store.dispatch('setReloadUserInfo', true)
          this.$router.push({ name: 'UserAccounts' })
        }, error => {
          this.isLoading = false
          this.errorMessage = this.$vuetify.lang.t('$vuetify.general.general_error')
          this.errorSnackbar = true
        })
      },
      setTitle () {
        document.title = this.form_title + ' | ' + this.$vuetify.lang.t('$vuetify.general.app_name')
      }
    },
    created () {
      this.setTitle()
    }
  }
</script>
import Vue from 'vue'

import LoadingDialog from '@/components/LoadingDialog'
import ErrorSnackbar from '@/components/ErrorSnackbar'
import SuccessSnackbar from '@/components/SuccessSnackbar'
import SessionExpired from '@/components/SessionExpired'
import LoggedMenu from '@/components/LoggedMenu'
import VideoViewer from '@/components/VideoViewer'
import AppBar from '../components/AppBar'
import PageDiv from '@/components/PageDiv'

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
})

Vue.component('loading-dialog', LoadingDialog)
Vue.component('error-snackbar', ErrorSnackbar)
Vue.component('success-snackbar', SuccessSnackbar)
Vue.component('logged-menu', LoggedMenu)
Vue.component('session-expired', SessionExpired)
Vue.component('video-viewer', VideoViewer)
Vue.component('app-bar', AppBar)
Vue.component('page-div', PageDiv)

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    isDataLoading: true,
    lang: 'en',
    user: null,
    login_data: {
      token: null,
      session_id: null,
      ed_team_id: null,
      account_id: null,
      account_type: null
    },
    redirect_to_program: null,
    reloadUserInfo: false,
    unauthorized_dialog: false
  },
  mutations: {
    setDataLoading (state, payload) {
      state.isDataLoading = payload
    },
    setLang (state, payload) {
      state.lang = payload
    },
    setReloadUserInfo (state, payload) {
      state.reloadUserInfo = payload
    },
    setLoginData (state, payload) {
      state.login_data = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setUnauthorizedDialog (state, payload) {
      state.unauthorized_dialog = payload
    },
    setRedirectToProgram (state, payload) {
      state.redirect_to_program = payload
    }
  },
  actions: {
    setDataLoading({commit}, payload){
      commit('setDataLoading', payload)
    },
    setLang({commit}, payload){
      commit('setLang', payload)
    },
    setReloadUserInfo({commit}, payload){
      commit('setReloadUserInfo', payload)
    },
    setLoginData({commit}, payload){
      commit('setLoginData', payload)
    },
    setUser({commit}, payload){
      commit('setUser', payload)
    },
    setUnauthorizedDialog({commit}, payload){
      commit('setUnauthorizedDialog', payload)
    },
    setRedirectToProgram({commit}, payload){
      commit('setRedirectToProgram', payload)
    }
  },
  getters: {
    lang (state) {
      return state.lang
    },
    login_data (state) {
      return state.login_data
    },
    user (state) {
      return state.user
    },
    token (state) {
      if (!state.login_data) {
        return null
      }
      return state.login_data.token
    },
    unauthorized_dialog (state) {
      return state.unauthorized_dialog
    }
  }
})